import { Nullable } from "@/models/CustomTypes";

export class ClientSelect {
  id: Nullable<number>;
  company: Nullable<string>;

  constructor(id?: Nullable<number>, company?: Nullable<string>) {
    this.id = id;
    this.company = company;
  }
}
