import { CreateClientCmd } from "@/context/clients/models/CreateClientCmd";
import { AxiosResponse } from "axios";
import { axiosInstance } from "@/plugins/axios";

export const baserUrl = "/api/clients";

// export async function find(query: string) {
//
//   const response = await axiosConfig.get(baserUrl + "?" + query);
//
//   return response;
// }

export const CLIENT_API = {
  baserUrl
};

export class CustomerApi {
  public static async create(data: CreateClientCmd): Promise<AxiosResponse> {
    return await axiosInstance.post(CLIENT_API.baserUrl + "/create", data);
  }
}
