




















import { CLIENT_API } from "@/api/CustomerApi";
import Component from "vue-class-component";
import { Prop, VModel } from "vue-property-decorator";
import Vue from "vue";
import { ClientSelect } from "@/models/client/ClientSelect";

@Component({})
export default class ClientAutocomplete extends Vue {
  @VModel({ type: ClientSelect })
  model: ClientSelect;
  @Prop({
    default: "",
    type: String
  })
  queryParam!: string;
  @Prop({ type: Array, default: () => [] })
  rules: [];
  @Prop({ type: Boolean, default: false })
  multiple: boolean;
  items: ClientSelect[] = [];
  loading = false;
  search = "";

  mounted(): void {
    this.loadItems();
  }

  async loadItems(): Promise<void> {
    this.loading = true;
    const response = await this.$http.get(
      CLIENT_API.baserUrl + "/?search=" + this.search + "&" + this.queryParam
    );
    this.items = response.data.map(item => new ClientSelect(item.id, item.company));
    this.loading = false;
  }
}
